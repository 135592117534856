.image-gallery-image {
  width: 100vw !important;
  height:600px !important;
  object-fit: cover !important;
}

.fullscreen .image-gallery-image{
  height: 100vh !important;
  width: 100vw !important;
  height: 100vh !important;
  max-height: 100vh !important;
}